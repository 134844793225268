/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        // dismiss site alert
            if (!Cookies.get('alert')) {
               $( ".event-notice" ).fadeIn();
                $(".dismiss").click(function() {
                    $(this).parents('#eventNotice').fadeOut();
                    // set the cookie for 24 hours
                    var date = new Date();
                    date.setTime(date.getTime() + 24 * 60 * 60 * 1000);
                    Cookies.set('alert', true, { expires: date, path: '/' });
                });
                
                $( ".site-notice" ).fadeIn();
                $(".dismiss").click(function() {
                    $(this).parents('#siteNotice').fadeOut();
                    // set the cookie for 24 hours
                    var date = new Date();
                    date.setTime(date.getTime() + 24 * 60 * 60 * 1000);
                    Cookies.set('alert', true, { expires: date, path: '/' });
                });
            }

        // toggles hamburger and nav open and closed states
        var removeClass = true;
          $(".hamburger").click(function () {
          $(".hamburger").toggleClass('is-active');
          $("#sideNav").toggleClass('sideNav-open');
          $(".sideNavBody").toggleClass('sideNavBody-push');
          removeClass = false;
        });

        $(".sideNav").click(function() {
          removeClass = false;
        });

        document.addEventListener('touchstart', function() {
          if (removeClass) {
             $(".hamburger").removeClass('is-active');
             $("#sideNav").removeClass('sideNav-open');
             $(".sideNavBody").removeClass('sideNavBody-push');
          }
          removeClass = true;
        }, false);

        // Scroll Up
        $(window).scroll(function () {
          if ($(this).scrollTop() > 100) {
            $('.scrollup').fadeIn();
          } else {
            $('.scrollup').fadeOut();
          }
        });

        $('.scrollup').click(function () {
          $("html, body").animate({
            scrollTop: 0
          }, 600);
          return false;
        });

        // Check Screen Size
        var screenSize;

        function checkSize() {
          if($('.test-size').css('font-size') === '1px') {
            screenSize = 'xs';
          } else if($('.test-size').css('font-size') === '2px') {
            screenSize = 'sm';
          } else if($('.test-size').css('font-size') === '3px') {
            screenSize = 'md';
          } else if($('.test-size').css('font-size') === '4px') {
            screenSize = 'lg';
          }
        }

        checkSize();

        $(window).resize(function(){
          checkSize();
        });

        function isScrolledIntoView(elem)
        {
          var docViewTop = $(window).scrollTop();
          var docViewBottom = docViewTop + $(window).height();

          var elemTop = $(elem).offset().top;
          var elemBottom = elemTop + $(elem).height();
          return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop) && docViewTop !== 0);
        }

        var showSwipe = false;

        $(window).scroll(function() {
          if($('.swipicon-container').length) {
            if(isScrolledIntoView($('.swipicon-container')) && !$('.swipicon').hasClass('swipiconActive') && !showSwipe && (screenSize === 'xs' || screenSize === 'sm')) {
              showSwipe = true;
              $('.swipicon').fadeIn(1500,function(){
                $('.swipicon').addClass('swipiconActive');
              }).delay(1600).fadeOut(1500);
            }
          }
        });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page

        // Google Map With Multiple Locations
        var map;
        var infowindow = new google.maps.InfoWindow();

        function initialize_gmap(map_lat, map_long, zoom) {
          var w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);

          var isDraggable = w > 480 ? true : false;

          var settings = {
            zoom: zoom,
            center: {lat: map_lat, lng: map_long},
            scrollwheel: false,
            draggable: isDraggable,
            mapTypeControl: true,
            mapTypeControlOptions: {style: google.maps.MapTypeControlStyle.DROPDOWN_MENU},
            navigationControl: true,
            navigationControlOptions: {style: google.maps.NavigationControlStyle.SMALL},
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            styles: [
            {
              "featureType": "administrative",
              "elementType": "labels.text.fill",
              "stylers": [
                {
                  "color": "#444444"
                }
              ]
            },
            {
              "featureType": "landscape",
              "elementType": "all",
              "stylers": [
                {
                  "color": "#f2f2f2"
                }
              ]
            },
            {
              "featureType": "poi",
              "elementType": "all",
              "stylers": [
                {
                  "visibility": "off"
                }
              ]
            },
            {
              "featureType": "road",
              "elementType": "all",
              "stylers": [
                {
                  "saturation": -100
                },
                {
                  "lightness": 45
                }
              ]
            },
            {
              "featureType": "road.highway",
              "elementType": "all",
              "stylers": [
                {
                  "visibility": "simplified"
                }
              ]
            },
            {
              "featureType": "road.arterial",
              "elementType": "labels.icon",
              "stylers": [
                {
                  "visibility": "off"
                }
              ]
            },
            {
              "featureType": "transit",
              "elementType": "all",
              "stylers": [
                {
                  "visibility": "off"
                }
              ]
            },
            {
              "featureType": "water",
              "elementType": "all",
              "stylers": [
                {
                  "color": "#507ac9"
                },
                {
                  "visibility": "on"
                }
              ]
            }]
          };

          map = new google.maps.Map(document.getElementById("map_canvas"), settings);

          var center;
          function calculateCenter() {
            center = map.getCenter();
          }
          google.maps.event.addDomListener(map, 'idle', function() {
            calculateCenter();
          });
          google.maps.event.addDomListener(window, 'resize', function() {
            map.setCenter(center);
          });
        }

        function addMarker(point_lat, point_long, address, title, directions) {

          var point = new google.maps.LatLng(point_lat, point_long);

          var contentString = '<div id="gmapContent" style="text-align:left;">'+
            '<a href="' + directions + '" target="_blank"><h5>' + title + '<br><small>(Click for directions)</small></h5></a>'+
            '<div id="bodyContent">'+
            '<p>' + address + '</p>'+
            '</div>'+
            '</div>';

          var companyMarker = new google.maps.Marker({
            position: point,
            map: map,
            title: title,
            icon: '/wp-content/themes/childrens-center/dist/images/map_marker.png',
          });

          google.maps.event.addListener(companyMarker, 'click', function() {
            infowindow.setContent(contentString);
            infowindow.open(map,this);
          });
        }

        var point_lat;
        var point_long;
        var address;
        var title;
        var directions;
        var map_name;

        function getMapData(map_name) {
          point_lat = parseFloat($(map_name).find('.map-data').find('.point_lat').data('pointlat'));
          point_long = parseFloat($(map_name).find('.map-data').find('.point_long').data('pointlong'));
          address = $(map_name).find('.map-data').find('.address').data('address');
          title = $(map_name).find('.map-data').find('.title').data('title');
          directions = $(map_name).find('.map-data').find('.directions').data('directions');
          addMarker(point_lat, point_long, address, title, directions);
        }

        if($('#map_canvas').length) {
          var map_lat = parseFloat($('#map_canvas').siblings('.map-data').find('.map_lat').data('maplat'));
          var map_long = parseFloat($('#map_canvas').siblings('.map-data').find('.map_long').data('maplong'));
          var zoom = parseFloat($('#map_canvas').siblings('.map-data').find('.zoom').data('zoom'));

          initialize_gmap(map_lat, map_long, zoom);
        }

        if($('.location-map-augusta').length) {
          map_name = '.location-map-augusta';
          getMapData(map_name);
        }

        if($('.location-map-skowhegan').length) {
          map_name = '.location-map-skowhegan';
          getMapData(map_name);
        }

        if($('.location-map-farmington').length) {
          map_name = '.location-map-farmington';
          getMapData(map_name);
        }

        if($('.location-map-waterville').length) {
          map_name = '.location-map-waterville';
          getMapData(map_name);
        }

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'locations': {
      init: function() {
        // JavaScript to be fired on the about us page
        
        // Google Map With Multiple Locations
        var map;
        var infowindow = new google.maps.InfoWindow();

        function initialize_gmap(map_lat, map_long, zoom) {
          var w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);

          var isDraggable = w > 480 ? true : false;

          var settings = {
            zoom: zoom,
            center: {lat: map_lat, lng: map_long},
            scrollwheel: false,
            draggable: isDraggable,
            mapTypeControl: true,
            mapTypeControlOptions: {style: google.maps.MapTypeControlStyle.DROPDOWN_MENU},
            navigationControl: true,
            navigationControlOptions: {style: google.maps.NavigationControlStyle.SMALL},
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            styles: [
            {
              "featureType": "administrative",
              "elementType": "labels.text.fill",
              "stylers": [
                {
                  "color": "#444444"
                }
              ]
            },
            {
              "featureType": "landscape",
              "elementType": "all",
              "stylers": [
                {
                  "color": "#f2f2f2"
                }
              ]
            },
            {
              "featureType": "poi",
              "elementType": "all",
              "stylers": [
                {
                  "visibility": "off"
                }
              ]
            },
            {
              "featureType": "road",
              "elementType": "all",
              "stylers": [
                {
                  "saturation": -100
                },
                {
                  "lightness": 45
                }
              ]
            },
            {
              "featureType": "road.highway",
              "elementType": "all",
              "stylers": [
                {
                  "visibility": "simplified"
                }
              ]
            },
            {
              "featureType": "road.arterial",
              "elementType": "labels.icon",
              "stylers": [
                {
                  "visibility": "off"
                }
              ]
            },
            {
              "featureType": "transit",
              "elementType": "all",
              "stylers": [
                {
                  "visibility": "off"
                }
              ]
            },
            {
              "featureType": "water",
              "elementType": "all",
              "stylers": [
                {
                  "color": "#507ac9"
                },
                {
                  "visibility": "on"
                }
              ]
            }]
          };

          map = new google.maps.Map(document.getElementById("map_canvas"), settings);

          var center;
          function calculateCenter() {
            center = map.getCenter();
          }
          google.maps.event.addDomListener(map, 'idle', function() {
            calculateCenter();
          });
          google.maps.event.addDomListener(window, 'resize', function() {
            map.setCenter(center);
          });
        }

        function addMarker(point_lat, point_long, address, title, directions) {

          var point = new google.maps.LatLng(point_lat, point_long);

          var contentString = '<div id="gmapContent" style="text-align:left;">'+
            '<a href="' + directions + '" target="_blank"><h5>' + title + '<br><small>(Click for directions)</small></h5></a>'+
            '<div id="bodyContent">'+
            '<p>' + address + '</p>'+
            '</div>'+
            '</div>';

          var companyMarker = new google.maps.Marker({
            position: point,
            map: map,
            title: title,
            icon: '/wp-content/themes/childrens-center/dist/images/map_marker.png',
          });

          google.maps.event.addListener(companyMarker, 'click', function() {
            infowindow.setContent(contentString);
            infowindow.open(map,this);
          });
        }

        var point_lat;
        var point_long;
        var address;
        var title;
        var directions;
        var map_name;

        function getMapData(map_name) {
          point_lat = parseFloat($(map_name).find('.map-data').find('.point_lat').data('pointlat'));
          point_long = parseFloat($(map_name).find('.map-data').find('.point_long').data('pointlong'));
          address = $(map_name).find('.map-data').find('.address').data('address');
          title = $(map_name).find('.map-data').find('.title').data('title');
          directions = $(map_name).find('.map-data').find('.directions').data('directions');
          addMarker(point_lat, point_long, address, title, directions);
        }

        if($('#map_canvas').length) {
          var map_lat = parseFloat($('#map_canvas').siblings('.map-data').find('.map_lat').data('maplat'));
          var map_long = parseFloat($('#map_canvas').siblings('.map-data').find('.map_long').data('maplong'));
          var zoom = parseFloat($('#map_canvas').siblings('.map-data').find('.zoom').data('zoom'));

          initialize_gmap(map_lat, map_long, zoom);
        }

        if($('.location-map-augusta').length) {
          map_name = '.location-map-augusta';
          getMapData(map_name);
        }

        if($('.location-map-skowhegan').length) {
          map_name = '.location-map-skowhegan';
          getMapData(map_name);
        }

        if($('.location-map-farmington').length) {
          map_name = '.location-map-farmington';
          getMapData(map_name);
        }

        if($('.location-map-waterville').length) {
          map_name = '.location-map-waterville';
          getMapData(map_name);
        }

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    'contact_us': {
      init: function() {

        // Google Map With Multiple Locations
        var map;
        var infowindow = new google.maps.InfoWindow();

        function initialize_gmap(map_lat, map_long, zoom) {
          var w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);

          var isDraggable = w > 480 ? true : false;

          var settings = {
            zoom: zoom,
            center: {lat: map_lat, lng: map_long},
            scrollwheel: false,
            draggable: isDraggable,
            mapTypeControl: true,
            mapTypeControlOptions: {style: google.maps.MapTypeControlStyle.DROPDOWN_MENU},
            navigationControl: true,
            navigationControlOptions: {style: google.maps.NavigationControlStyle.SMALL},
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            styles: [
            {
              "featureType": "administrative",
              "elementType": "labels.text.fill",
              "stylers": [
                {
                  "color": "#444444"
                }
              ]
            },
            {
              "featureType": "landscape",
              "elementType": "all",
              "stylers": [
                {
                  "color": "#f2f2f2"
                }
              ]
            },
            {
              "featureType": "poi",
              "elementType": "all",
              "stylers": [
                {
                  "visibility": "off"
                }
              ]
            },
            {
              "featureType": "road",
              "elementType": "all",
              "stylers": [
                {
                  "saturation": -100
                },
                {
                  "lightness": 45
                }
              ]
            },
            {
              "featureType": "road.highway",
              "elementType": "all",
              "stylers": [
                {
                  "visibility": "simplified"
                }
              ]
            },
            {
              "featureType": "road.arterial",
              "elementType": "labels.icon",
              "stylers": [
                {
                  "visibility": "off"
                }
              ]
            },
            {
              "featureType": "transit",
              "elementType": "all",
              "stylers": [
                {
                  "visibility": "off"
                }
              ]
            },
            {
              "featureType": "water",
              "elementType": "all",
              "stylers": [
                {
                  "color": "#507ac9"
                },
                {
                  "visibility": "on"
                }
              ]
            }]
          };

          map = new google.maps.Map(document.getElementById("map_canvas"), settings);

          var center;
          function calculateCenter() {
            center = map.getCenter();
          }
          google.maps.event.addDomListener(map, 'idle', function() {
            calculateCenter();
          });
          google.maps.event.addDomListener(window, 'resize', function() {
            map.setCenter(center);
          });
        }

        function addMarker(point_lat, point_long, address, title, directions) {

          var point = new google.maps.LatLng(point_lat, point_long);

          var contentString = '<div id="gmapContent" style="text-align:left;">'+
            '<a href="' + directions + '" target="_blank"><h5>' + title + '<br><small>(Click for directions)</small></h5></a>'+
            '<div id="bodyContent">'+
            '<p>' + address + '</p>'+
            '</div>'+
            '</div>';

          var companyMarker = new google.maps.Marker({
            position: point,
            map: map,
            title: title,
            icon: '/wp-content/themes/childrens-center/dist/images/map_marker.png',
          });

          google.maps.event.addListener(companyMarker, 'click', function() {
            infowindow.setContent(contentString);
            infowindow.open(map,this);
          });
        }

        var point_lat;
        var point_long;
        var address;
        var title;
        var directions;
        var map_name;

        function getMapData(map_name) {
          point_lat = parseFloat($(map_name).find('.map-data').find('.point_lat').data('pointlat'));
          point_long = parseFloat($(map_name).find('.map-data').find('.point_long').data('pointlong'));
          address = $(map_name).find('.map-data').find('.address').data('address');
          title = $(map_name).find('.map-data').find('.title').data('title');
          directions = $(map_name).find('.map-data').find('.directions').data('directions');
          addMarker(point_lat, point_long, address, title, directions);
        }

        if($('#map_canvas').length) {
          var map_lat = parseFloat($('#map_canvas').siblings('.map-data').find('.map_lat').data('maplat'));
          var map_long = parseFloat($('#map_canvas').siblings('.map-data').find('.map_long').data('maplong'));
          var zoom = parseFloat($('#map_canvas').siblings('.map-data').find('.zoom').data('zoom'));

          initialize_gmap(map_lat, map_long, zoom);
        }

        if($('.location-map-augusta').length) {
          map_name = '.location-map-augusta';
          getMapData(map_name);
        }

        if($('.location-map-skowhegan').length) {
          map_name = '.location-map-skowhegan';
          getMapData(map_name);
        }

        if($('.location-map-farmington').length) {
          map_name = '.location-map-farmington';
          getMapData(map_name);
        }

        if($('.location-map-waterville').length) {
          map_name = '.location-map-waterville';
          getMapData(map_name);
        }
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
